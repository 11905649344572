import {
    Badge,
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    styled,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {THEME_CONFIG} from "../../../config";
import {ArrowDownward, ArrowUpward} from "@mui/icons-material";

Array.prototype.move = function (from, to) {
    this.splice(to, 0, this.splice(from, 1)[0]);
};

const {poll} = THEME_CONFIG

const ReorderQuestion = ({risposte, answer, sendAnswer}) => {

    console.log("answer:",answer)
    const [orderedAnswers, setOrderedAnswers] = useState()

    useEffect(() => {
        if(answer !== '')
            setOrderedAnswers(answer)
        else
            setOrderedAnswers(risposte)
    }, [answer])

    const RispostaStyled = styled(ListItem)({
        border: `2px solid ${poll.default}`,
        borderRadius: '0.7rem',
        background: poll.default,
        margin: '1rem 0',
        cursor: 'pointer',
    })

    function moveUpward(r, i) {
        const newAnswers = [...orderedAnswers]
        newAnswers?.move(i, i-1)
        console.log("moveUpward > newAnswers:",newAnswers)

        setOrderedAnswers(newAnswers)
    }

    function moveDownward(r, i) {
        const newAnswers = [...orderedAnswers]
        newAnswers?.move(i, i+1)
        console.log("moveDownward > newAnswers:",newAnswers)
        setOrderedAnswers(newAnswers)
    }

    const rispondi = () => {
        if(answer === '') {
            console.log("orderedAnswers:",orderedAnswers)
            sendAnswer(orderedAnswers)
        }
    }

    return (
        <><List>
            {orderedAnswers?.map((r, i) => (
            <Badge key={i} color="primary" badgeContent={i+1} sx={{display: 'grid'}}
                   overlap="circular"
                   anchorOrigin={{
                       vertical: 'top',
                       horizontal: 'right',
                   }}
            >
                <RispostaStyled style={{
                    width: '100%',
                    //background: computeBkg(r),
                    border: `2px solid ${poll.default}`,
                    color: 'white',
                }}>
                    <Typography variant={"h6"} fontWeight='400'>
                        {r}
                    </Typography>
                    <ListItemSecondaryAction>
                        <IconButton size={'small'} disabled={i === 0 || answer !== ''} onClick={() => moveUpward(r, i)}>
                            <ArrowUpward/>
                        </IconButton>
                        <IconButton size={'small'} disabled={i === risposte.length-1 || answer !== ''} onClick={() => moveDownward(r, i)}>
                            <ArrowDownward/>
                        </IconButton>
                    </ListItemSecondaryAction>
                </RispostaStyled>
            </Badge>))
            }
        </List>
            <Button sx={{mt: 4}}
                    disabled={answer !== ''}
                    variant={'wordcloud'}
                    onClick={rispondi}>
                Invia
            </Button>
        </>
    )
}

export default ReorderQuestion
