export const API_URL = "https://api.secondstage.app"
//export const API_URL = "http://localhost:8080"
//export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"
export const TOOLPRESS_API = "https://toolpress-api.2ndstage.app"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#fff', '#fff'],
        home_question: ['#fff', '#fff'],
        applausometro: ['rgba(48,78,152,0)', 'rgba(48,78,152,0)'],
        siNo:['#3a4de4', '#00d8a1'],
        sentiment: ['#3a4de4', '#00d8a1'],
        wordcloud: ['#EA4D95', '#E76E51'],
        qna: ['#fff', '#fff'],
    },
    primary: '#fff',
    secondary: '#263238',
    accent: '#fff',
    text: '#eceff1',
    background:"#304e98",
    poll:{
        default:"#fff",
        answered:"#304e98",
        correct: '#3A722D',
        wrong: '#DB0A16'
    }
}